import React from 'react';
import {
  DontDo,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Environment"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Themes"
        tierThree="Environment"
      />

      <PageNavigation links={['Options', 'Usage', 'Platform']} />

      <Section title="Options">
        <img
          src={require('../../../../imgs/diagrams/environment-light-vs-dark.png')}
          className="uni-margin--two--bottom"
        />
        <Paragraph>
          Use only one environment per interface. If you’re unsure of which to
          choose, use light.
        </Paragraph>
        <List>
          <li>
            <strong>Light Environment</strong> (LE) — Our default environment
            fits browsing experiences for everyday work, such as viewing or
            managing information.
          </li>
          <li>
            <strong>Dark Environment</strong> (DE) — For immersive content
            experiences, like video editing or tagging.
          </li>
        </List>
      </Section>

      <Section title="Usage">
        <SectionSubhead>One Environment</SectionSubhead>
        <Paragraph>Use just one environment per interface.</Paragraph>
        <DontDo
          dontText="use a different environment for sub-regions."
          doText="stick with one environment throughout an interface."
          imgFilename="themes-subregions"
        />

        <SectionSubhead>Switching Environments</SectionSubhead>
        <Paragraph>
          Switching environments dramatically changes the interface. While this
          shift can be an effective experience tool (e.g., opening the video
          player), it’s more often a disorienting user experience.
        </Paragraph>
        <DontDo
          dontText="change environments during an experience flow."
          doText="change environments with a major context switch."
          imgFilename="themes-change"
        />
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
